import React, { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Input } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import If from '@components/If';
import SearchIcon from '@components/SearchInput/SearchIcon';
import useChangeOrganizations from '@core/hooks/useChangeOrganizations';
import useProfile from '@core/hooks/useProfile';
import { UserOrganizationModel } from '@core/types';
import MenuItemIcon from '../../MenuItemIcon';

const OrganizationsMenu = () => {
  const { organizations, userUid } = useProfile();
  const changeOrganization = useChangeOrganizations();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const onOrganizationChange = (organizationItem: UserOrganizationModel) => {
    changeOrganization({
      userUid,
      organizationProductId: organizationItem.organizationProductId,
      organizations,
    });
    localStorage.removeItem('savedSelectedFilters');
  };

  const filteredOrganizations = organizations?.filter((org) =>
    org.organizationName.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <If condition={(organizations || []).length > 1}>
      <ListItemButton
        onClick={() => {
          setOpen(!open);
        }}
      >
        <MenuItemIcon>autorenew</MenuItemIcon>
        <ListItemText primary="Cambiar de organización" />
        <If condition={open} fallback={<ExpandMore />}>
          <ExpandLess />
        </If>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Autocomplete
            options={organizations || []}
            getOptionLabel={(option) => option.organizationName}
            onInputChange={(event, newInputValue) => {
              setSearchValue(newInputValue);
            }}
            renderInput={(params) => (
              <Input
                {...params}
                placeholder="Buscar organización"
                fullWidth
                startAdornment={<SearchIcon />}
              />
            )}
          />

          {filteredOrganizations?.map((organizationItem) => (
            <ListItemButton
              onClick={() => {
                onOrganizationChange(organizationItem);
              }}
              key={organizationItem.organizationProductId}
              sx={{ pl: 8 }}
            >
              <ListItemText primary={organizationItem.organizationName} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </If>
  );
};

export default OrganizationsMenu;
