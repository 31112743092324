import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import If from '@components/If';
import useOrganizationSettings from '@core/hooks/useOrganizationSettings';
import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import useStudyInfo from '@core/hooks/useStudyInfo';
import useWidth from '@core/hooks/useWidth';
import { EmailFormRequest } from '@core/types';
import useUpdatePendingReport from 'app/features/study/hooks/updatePendingReport';
import useGetEditableReport from 'app/features/study/hooks/useGetEditableReport';
import useRejectReport from 'app/features/study/hooks/useRejectReport';
import useShareByEmail from 'app/features/study/hooks/useShareByEmail';
import useUpdateReport from 'app/features/study/hooks/useUpdateReport';
import useUploadReport from 'app/features/study/hooks/useUploadReport';
import Form from './Form';
import { ReportDocument } from './Form/documentTypes';

const Reports = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const { organizationSettings } = useOrganizationSettings();
  const studyUid = searchParams.get('studyUid') || '';
  const SOPInstanceUID = searchParams.get('SOPInstanceUID') || '';
  const SeriesInstanceUID = searchParams.get('SeriesInstanceUID') || '';
  const Draft = searchParams.get('borrador') || '';
  const width = useWidth();
  const deviceWidth = width === 'xs' ? 'sm' : width;
  const { uploadReport, isUploading } = useUploadReport(studyUid);
  const { reportUpdate, isUpdatingReport } = useUpdateReport(studyUid);
  const { rejectPendingReport, isRejecting } = useRejectReport();
  const { updatePendingReport, isUpdating } = useUpdatePendingReport();
  const { userUid } = useProfile();
  const { share } = useShareByEmail(studyUid);
  const { studyInfo } = useStudyInfo({ studyInstanceUidOrHash: studyUid });
  const showSnackbar = useSnackbar();

  const isDraft = Draft === 'true';

  const { editableReport, isLoading } = useGetEditableReport({
    studyInstanceUid: studyUid,
    sopInstanceUid: SOPInstanceUID,
    isDraft,
    enabled: !!SOPInstanceUID.length,
  });

  const loggedUserCreatedReport = userUid === editableReport?.createdBy?.userId;
  const reportIsPendingReview = editableReport?.reportStatusId === 2;

  const handleShareByEmail = useCallback(async () => {
    if (!studyInfo?.patientsEmail || !studyInfo?.patientsFullName) {
      console.error('Patient email or name is undefined');
      return;
    }

    try {
      const req = {
        body: '',
        email: studyInfo.patientsEmail,
        name: studyInfo.patientsFullName,
      };

      await share(req as EmailFormRequest);
    } catch (error: any) {
      console.error(error);
    }
  }, [studyInfo, share]);

  async function uploadNewReport(report: ReportDocument) {
    try {
      const response = await uploadReport(report);

      if (response.success) {
        await handleShareByEmail();
        window?.opener?.onSuccessResponse?.();
        showSnackbar({
          type: 'success',
          title: 'Reporte creado',
          message: 'El reporte se ha creado.',
        });
        return;
      }

      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'No ha sido posible crear el reporte.',
      });
    } catch (error) {
      console.log('Error', error);
    }
  }

  const handleReportSubmit = async (report: ReportDocument) => {
    if (!studyUid) return;

    const saveAsDraft = report.saveAsDraft;

    // Update Report draft/pending
    if (saveAsDraft) {
      if (editableReport?.reportStatusId === 2 || editableReport?.reportStatusId === 4) {
        const response = await updatePendingReport({
          reportId: SOPInstanceUID,
          reportStatusId: editableReport?.reportStatusId,
          report,
        });
        if (response.success) {
          window?.opener?.onSuccessResponse?.();
          showSnackbar({
            type: 'success',
            title: 'Reporte actualizado',
            message: 'El reporte se ha actualizado.',
          });
          return;
        }

        showSnackbar({
          type: 'error',
          title: 'Ocurrió un error',
          message: 'No ha sido posible actualizar el reporte.',
        });
        return;
      } else {
        uploadNewReport(report);
        return;
      }
    }

    // Save Draft as Pending if setting is enabled
    if (
      organizationSettings.StudyDoubleReading === 'True' &&
      editableReport?.reportStatusId === 4
    ) {
      const response = await updatePendingReport({
        reportId: SOPInstanceUID,
        reportStatusId: 2,
        report,
      });
      if (response.success) {
        window?.opener?.onSuccessResponse?.();
        showSnackbar({
          type: 'success',
          title: 'Reporte actualizado',
          message: 'El reporte se ha actualizado.',
        });
        return;
      }

      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'No ha sido posible actualizar el reporte.',
      });
      return;
    }

    // Handles Pending Reports depending on logged user
    if (reportIsPendingReview) {
      if (loggedUserCreatedReport) {
        const response = await updatePendingReport({
          reportId: SOPInstanceUID,
          reportStatusId: 2,
          report,
        });
        if (response.success) {
          window?.opener?.onSuccessResponse?.();
          showSnackbar({
            type: 'success',
            title: 'Reporte actualizado',
            message: 'El reporte se ha actualizado.',
          });
          return;
        }

        showSnackbar({
          type: 'error',
          title: 'Ocurrió un error',
          message: 'No ha sido posible actualizar el reporte.',
        });
        return;
      } else {
        uploadNewReport({ ...report, reportId: SOPInstanceUID });
        return;
      }
    }

    // If there is SOPInstanceUID (Report already exists) updates the report
    if (SOPInstanceUID && SeriesInstanceUID) {
      const response = await reportUpdate({
        report,
        sopInstanceUid: SOPInstanceUID,
        seriesInstanceUid: SeriesInstanceUID,
      });

      if (response.success) {
        window?.opener?.onSuccessResponse?.();
        showSnackbar({
          type: 'success',
          title: 'Reporte actualizado',
          message: 'El reporte se ha actualizado.',
        });
        return;
      }

      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'No ha sido posible actualizar el reporte.',
      });
      return;
    } else uploadNewReport(report);
  };

  function openRejectModal() {
    if (!studyUid || !isDraft) return;
    setIsModalOpen(true);
  }

  function closeRejectModal() {
    setIsModalOpen(false);
  }

  async function handleRejectReport() {
    const response = await rejectPendingReport(SOPInstanceUID);

    if (response.success) {
      window?.opener?.onSuccessResponse?.();
      showSnackbar({
        type: 'success',
        title: 'Reporte rechazado',
        message: 'El reporte se ha rechazado.',
      });
      return;
    }

    showSnackbar({
      type: 'error',
      title: 'Ocurrió un error',
      message: 'No ha sido posible rechazar el reporte.',
    });
  }

  const onCancel = () => {
    window?.opener?.onCancelReport();
  };

  return (
    <Container sx={{ height: '100%', paddingY: '20px' }} maxWidth={deviceWidth} disableGutters>
      <If condition={!isLoading || !SOPInstanceUID}>
        <Form
          studyId={studyUid ?? ''}
          encodedDocument={editableReport?.encodedDocument}
          initForm={{ name: editableReport?.title || '' }}
          handleCancel={onCancel}
          handleReportSubmit={handleReportSubmit}
          handleRejectReport={openRejectModal}
          reportStatusId={editableReport?.reportStatusId}
          loggedUserCreatedReport={loggedUserCreatedReport}
          isLoading={isUploading || isRejecting || isUpdating || isUpdatingReport}
          createdBy={editableReport?.createdBy}
        />
      </If>
      <ActionDialog
        open={isModalOpen}
        title={'¿Desea rechazar el reporte?'}
        type="delete"
        onClose={closeRejectModal}
        onCancel={closeRejectModal}
        onConfirm={handleRejectReport}
      />
    </Container>
  );
};

export default Reports;
