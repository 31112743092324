import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import { Patient } from '@core/types/Patient';
import TechnicianForm from 'app/features/studies/components/AddTechnicianForm/Form';
import withSendAssignedNotification from 'app/features/studies/components/WithSendAssignedNotification/withSendAssignedNotification';
import useUpdateStudyInformation from 'app/features/studies/hooks/useUpdateStudyInformation';
import useOverridePatient from 'app/features/study/hooks/useOverridePatient';
import useStudyInfo from '../../../../../core/hooks/useStudyInfo';
import ManagerComponentProps from '../../../types/ManagerComponentProps';
import Comments from '../Comments';
import OverridePatientFormDialog from '../OverridPatientFormDialog';
import PatientStudies from '../PatientStudies';
import SolicitationManager from '../SolicitationManager';
import AccordionHeader from './AccordionHeader';
import LoadingIndicator from './LoadingIndicator';
import NoInfoAvailable from './NoInfoAvailable';
import PatientInfo from './PatientInfo/PatientInfo';
import StudyInfo from './StudyInfo';
import useSx from './sx';

const StudyInformation = ({ studyInstanceUid, readOnly, onClose }: ManagerComponentProps) => {
  const [searchParams] = useSearchParams();
  const studyId = readOnly ? searchParams.get('s') ?? '' : studyInstanceUid;
  const { isLoading, studyInfo, refetch } = useStudyInfo({
    studyInstanceUidOrHash: studyId,
    isSharedStudy: readOnly,
  });
  const { updateInformationAsync, isUploading } = useUpdateStudyInformation();
  const sx = useSx();
  const { role } = useProfile();

  const TechnicianFormWithNotification = withSendAssignedNotification(TechnicianForm);

  const { override } = useOverridePatient(studyInfo?.studyInstanceUid || '');

  const [isOpen, setIsOpen] = useState(false);
  const [newPatient, setNewPatient] = useState<Patient | null>(null);
  const [existingPatient, setExistingPatient] = useState<Patient | null>(null);

  const [collapseStatus, setCollapseStatus] = useState({
    patientInfo: true,
    studyInfo: true,
    patientHistory: false,
    technician: true,
    studySolicitation: true,
    comments: true,
  });

  return (
    <Container maxWidth="md" sx={sx.root}>
      <If condition={!isLoading} fallback={<LoadingIndicator />}>
        <If condition={!!studyInfo} fallback={<NoInfoAvailable />}>
          <AccordionHeader
            title="Información del paciente"
            onClick={() =>
              setCollapseStatus((prev) => ({ ...prev, patientInfo: !prev.patientInfo }))
            }
            collapseStatus={collapseStatus.patientInfo}
          />
          <If condition={collapseStatus.patientInfo}>
            <PatientInfo
              key={JSON.stringify(studyInfo)}
              study={studyInfo!}
              role={role}
              refetch={refetch}
              update={updateInformationAsync}
              isUpdating={isUploading}
              onAddNewPatient={({
                existingPatient: existingPatientProp,
                newPatient: newPatientProp,
              }) => {
                setIsOpen(true);
                setNewPatient(newPatientProp);
                setExistingPatient(existingPatientProp);
              }}
            />
          </If>
          <AccordionHeader
            title="Historial del paciente"
            onClick={() =>
              setCollapseStatus((prev) => ({ ...prev, patientHistory: !prev.patientHistory }))
            }
            collapseStatus={collapseStatus.patientHistory}
          />
          <If condition={!!studyInfo?.patientId && collapseStatus.patientHistory}>
            <PatientStudies
              patientId={studyInfo!.patientId!}
              applyFilter={(study) => study.studyInstanceUid !== studyInfo?.studyInstanceUid}
            />
          </If>
          <AccordionHeader
            title="Informacion del estudio"
            onClick={() => setCollapseStatus((prev) => ({ ...prev, studyInfo: !prev.studyInfo }))}
            collapseStatus={collapseStatus.studyInfo}
          />
          <If condition={collapseStatus.studyInfo}>
            <StudyInfo
              key={JSON.stringify(studyInfo)}
              study={studyInfo!}
              role={role}
              refetch={refetch}
              update={updateInformationAsync}
              isUpdating={isUploading}
              onAddNewPatient={({
                existingPatient: existingPatientProp,
                newPatient: newPatientProp,
              }) => {
                setIsOpen(true);
                setNewPatient(newPatientProp);
                setExistingPatient(existingPatientProp);
              }}
            />
          </If>
          <AccordionHeader
            title="Técnico asignado"
            onClick={() => setCollapseStatus((prev) => ({ ...prev, technician: !prev.technician }))}
            collapseStatus={collapseStatus.technician}
          />
          <If condition={!!studyInfo?.studyInstanceUid && collapseStatus.technician}>
            <TechnicianFormWithNotification studyInstanceUid={studyInstanceUid} role={role} open />
          </If>
          <AccordionHeader
            title="Solicitud del estudio"
            onClick={() =>
              setCollapseStatus((prev) => ({ ...prev, studySolicitation: !prev.studySolicitation }))
            }
            collapseStatus={collapseStatus.studySolicitation}
          />
          <If condition={!!studyInfo?.studyInstanceUid && collapseStatus.studySolicitation}>
            <SolicitationManager studyInstanceUid={studyInstanceUid} />
          </If>

          <AccordionHeader
            title="Comentarios"
            onClick={() => setCollapseStatus((prev) => ({ ...prev, comments: !prev.comments }))}
            collapseStatus={collapseStatus.comments}
          />
          <If condition={collapseStatus.comments}>
            <Comments studyInstanceUid={studyInstanceUid} open disablePagination />
          </If>
          <OverridePatientFormDialog
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            existingPatient={existingPatient!}
            newPatient={newPatient!}
            onSubmit={async (payload) => {
              await override(payload);
            }}
          />
        </If>
      </If>
    </Container>
  );
};

export default StudyInformation;
