import commandsModule from './commandsModule';
import toolbarModule from './toolbarModule';

const evodicomExtension = (generatedReportIds, reportsCreatedByUser, hasPendingOrDraftReport) => ({
  id: 'evodicom',
  getToolbarModule() {
    return toolbarModule(generatedReportIds, reportsCreatedByUser, hasPendingOrDraftReport);
  },
  getCommandsModule() {
    return commandsModule;
  },
});

export default evodicomExtension;
