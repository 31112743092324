import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Formik, Form, FormikState, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Input } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import ActionButton from '@components/Button/ActionButton';
import CountryCodeDropdown from '@components/CountryCodeDropdown';
import DatePickerWithLabel from '@components/DatePickerWithLabel';
import DropdownWithLabel from '@components/DrodpdownWithLabel';
import If from '@components/If';
import ViewerTextField from '@components/ViewerTextField';
import RegexPatterns from '@core/constants/RegexPatterns';
import useConfirm from '@core/hooks/useConfirm';
import useOrganizationInfo from '@core/hooks/useOrganizationInfo';
import useSnackbar from '@core/hooks/useSnackbar';
import { RoleName } from '@core/types';
import { Patient } from '@core/types/Patient';
import updateStudy from 'app/features/study/api/updateStudy';
import useGetPatient from 'app/features/study/hooks/useGetPatient';
import useShareByEmailOrPhone from 'app/features/study/hooks/useShareByEmailOrPhone';
import Actions from '../Actions';
import PatientInfoProps from './PatientInfoProps';
import useSx from './sx';

const validationSchema = Yup.object().shape({
  patientsAge: Yup.string(),
  patientsGivenName: Yup.string(),
  patientsSurname: Yup.string(),
  patientsGender: Yup.string().oneOf(['M', 'F', 'O'], 'El género debe ser M, F u O').max(1),
  patientsEmail: Yup.string(),
  patientsPhoneNumber: Yup.string(),
  studyDescription: Yup.string(),
  referringPhysiciansName: Yup.string(),
});

const PatientInfo = ({
  study,
  role,
  refetch,
  update,
  isUpdating,
  onClose,
  onAddNewPatient,
}: PatientInfoProps) => {
  const {
    patientsFirstName,
    patientsLastName,
    patientsBirthDate,
    patientsAge,
    patientsGender,
    patientsEmail,
    patientsPhoneNumber,
    studyDescription,
    referringPhysiciansName,
    studyInstanceUid,
    shareUrl,
  } = study;
  const sx = useSx();
  const showSnackbar = useSnackbar();
  const { share, shareWhatsapp, isSharing } = useShareByEmailOrPhone(study.studyInstanceUid);
  const { organizationInfo } = useOrganizationInfo();

  const initialValues = useMemo(() => {
    const phoneNumber = patientsPhoneNumber;

    const matches = phoneNumber?.match(RegexPatterns.PHONE_NUMBER) || null;
    return {
      patientsBirthDate: dayjs(patientsBirthDate) || null,
      patientsGivenName: patientsFirstName || '',
      patientsSurname: patientsLastName || '',
      patientsAge: patientsAge || '',
      patientsGender: patientsGender?.trim() || 'O',
      patientsEmail: patientsEmail || '',
      patientsPhoneNumber: matches?.[2] || '',
      studyDescription: studyDescription || '',
      referringPhysiciansName: referringPhysiciansName || '',
      patientsCountryCodePhoneNumber: matches?.[1] || '',
      patientId: study.patientId || '',
    };
  }, [
    patientsBirthDate,
    patientsFirstName,
    patientsLastName,
    patientsAge,
    patientsGender,
    patientsEmail,
    patientsPhoneNumber,
    studyDescription,
    referringPhysiciansName,
    study.patientId,
  ]);

  const { isConfirmed } = useConfirm();

  const handleSharingByWhatsAppAndEmail = async (
    phoneNumber: string,
    email: string,
    name: string,
  ) => {
    const confirmed = await isConfirmed({
      title: '¿Deseas compartir el estudio al email y número registrado?',
      message: `Has actualizado el número de teléfono a +${phoneNumber} y email a ${email}. ¿Te gustaría compartir el estudio a este número y correo electrónico?`,
      type: 'info',
    });
    if (confirmed) {
      await share({ emailOrPhone: email, name, language: 'es_MX' });
      await shareWhatsapp({ emailOrPhone: phoneNumber, language: '', name });
    }

    return confirmed;
  };

  const handleShareByEmail = async (email: string, name: string) => {
    const confirmed = await isConfirmed({
      title: '¿Deseas compartir el estudio por correo electrónico?',
      message: `Has actualizado el correo electrónico a ${email}. ¿Te gustaría compartir el estudio por correo electrónico?`,
      type: 'info',
    });
    if (confirmed) {
      await share({ emailOrPhone: email, name, language: 'es_MX' });
    }
    return confirmed;
  };

  const handleShareByPhone = async (phoneNumber: string, name: string) => {
    const confirmed = await isConfirmed({
      title: '¿Deseas compartir el estudio al número registrado?',
      message: `Has actualizado el número de teléfono a +${phoneNumber}. ¿Te gustaría compartir el estudio a este número?`,
      type: 'info',
    });
    if (confirmed) {
      await shareWhatsapp({
        emailOrPhone: phoneNumber,
        language: 'es_MX',
        name,
        organizationPhone: organizationInfo.organizationPhone,
        organizationName: organizationInfo.organizationName,
        shareLink: shareUrl,
      });
    }
    return confirmed;
  };

  const handleShareChangedEmailAndPhoneNumber = async (
    phoneNumber: string,
    email: string,
    name: string,
  ) => {
    const hasChangedPhoneNumber =
      phoneNumber !== patientsPhoneNumber && RegexPatterns.PHONE_NUMBER.test(phoneNumber);
    const hasChangedEmail = email !== initialValues.patientsEmail && email !== '';
    const formattedPhoneNumber = phoneNumber.replace(/\((\d+)\)(\d+)/, '$1$2');

    if (hasChangedPhoneNumber && hasChangedEmail) {
      return await handleSharingByWhatsAppAndEmail(formattedPhoneNumber, email, name);
    } else if (hasChangedEmail) {
      return await handleShareByEmail(email, name);
    } else if (hasChangedPhoneNumber) {
      return await handleShareByPhone(formattedPhoneNumber, name);
    }
  };

  const onSubmit = async (propValues: typeof initialValues) => {
    const values = { ...propValues };
    values.patientsPhoneNumber = `(${values.patientsCountryCodePhoneNumber})${values.patientsPhoneNumber}`;
    const patientsBirthDateFormatted =
      values.patientsBirthDate && values.patientsBirthDate.isValid()
        ? values.patientsBirthDate.toISOString()
        : null;
    const fullName = values.patientsGivenName + ' ' + values.patientsSurname;

    const confirmed = await handleShareChangedEmailAndPhoneNumber(
      values.patientsPhoneNumber,
      values.patientsEmail,
      fullName,
    );

    let successMessage = 'La información del estudio ha sido guardada';

    if (confirmed) {
      successMessage =
        'La información del estudio ha sido guardada exitosamente. Además, se ha compartido a los medios confirmados.';
    }

    const payload = { ...values, studyInstanceUid };
    // Evodicom Service
    const response = await update({ ...payload, patientsBirthDate: patientsBirthDateFormatted });

    // Dicom Service
    await updateStudy(studyInstanceUid, payload);

    refetch();
    if (response.error) {
      showSnackbar({
        message: 'La información no ha sido guardada',
        title: 'Ocurrió un error',
        type: 'error',
      });
    } else {
      showSnackbar({
        message: successMessage,
        title: 'Guardado con éxito',
        type: 'success',
      });
    }
  };

  const handleCancel = (
    resetForm: (
      nextState?: Partial<FormikState<typeof initialValues> | undefined> | undefined,
    ) => void,
  ) => {
    resetForm();
    if (onClose) onClose();
  };

  const formikRef = React.useRef<FormikProps<typeof initialValues>>(null);

  const { getPatientAsync, isLoading } = useGetPatient();
  const [existingPatient, setExistingPatient] = useState<Patient | null>(null);

  const validatePatient = async (patientId: string) => {
    const data = await getPatientAsync(patientId);
    if (data?.success) {
      formikRef.current?.setFieldError('patientId', 'El ID del paciente ya existe');
      setExistingPatient(data?.result);
      return;
    }

    formikRef.current?.setFieldError('patiendId', undefined);
    setExistingPatient(null);
  };

  function handleVerifyPatient(values: typeof initialValues) {
    if (existingPatient) {
      onAddNewPatient({
        existingPatient,
        newPatient: {
          patientsNameFamily: values.patientsSurname,
          patientsNameGiven: values.patientsGivenName,
          patientsPhoneNumber: values.patientsPhoneNumber,
          patientsEmail: values.patientsEmail,
          patientsBirthDate: values.patientsBirthDate.toString(),
          patientsAge: values.patientsAge,
          patientId: initialValues.patientId,
          patientKey: 0,
          issuerOfPatientId: '',
          patientsSex: '',
          patientsNameMiddle: '',
          patientsNamePrefix: '',
          patientsNameSuffix: '',
          ethnicGroup: '',
        },
      });
    }
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        resetForm,
        setFieldValue,
      }) => (
        <Form>
          <Stack sx={sx.root}>
            <ViewerTextField
              name="patientId"
              onChange={handleChange}
              onBlur={(event) => {
                if (values.patientId !== initialValues.patientId) {
                  validatePatient(values.patientId);
                }
                handleBlur(event);
              }}
              className="viewer-textfield"
              label="Id del paciente"
              value={values.patientId}
              helperText={errors.patientId}
              error={Boolean(errors.patientId)}
            />
            <If condition={existingPatient !== null}>
              <ActionButton
                onClick={() => handleVerifyPatient(values)}
                sx={{ marginLeft: 'auto' }}
                variant="contained"
                color="primary"
                text="Verificar"
              />
            </If>
            <ViewerTextField
              name="patientsGivenName"
              onChange={handleChange}
              onBlur={handleBlur}
              className="viewer-textfield"
              label="Nombre del paciente"
              value={values.patientsGivenName}
            />
            <ViewerTextField
              name="patientsSurname"
              onChange={handleChange}
              onBlur={handleBlur}
              className="viewer-textfield"
              label="Apellido del paciente"
              value={values.patientsSurname}
            />
            <ViewerTextField
              className="viewer-textfield"
              label="Edad"
              name="patientsAge"
              value={values.patientsAge}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.patientsAge && Boolean(errors.patientsAge)}
              helperText={touched.patientsAge && errors.patientsAge}
              readOnly={
                role !== RoleName.Radiologist &&
                role !== RoleName.Technician &&
                role !== RoleName.System
              }
            />
            <DatePickerWithLabel
              label="Fecha de nacimiento"
              value={values.patientsBirthDate}
              onChange={(value) => {
                setFieldValue('patientsBirthDate', value);
              }}
            />
            <Box className="datepicker-container">
              <InputLabel>Número de teléfono</InputLabel>
              <Box className="phone-number">
                <CountryCodeDropdown
                  name="patientsCountryCodePhoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ width: '15%' }}
                  label="Código"
                  value={values.patientsCountryCodePhoneNumber}
                />
                <Input
                  name="patientsPhoneNumber"
                  placeholder="Numero"
                  value={values.patientsPhoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ flex: 1 }}
                />
              </Box>
            </Box>
            <DropdownWithLabel
              label="Género"
              className="gender-dropdown"
              name="patientsGender"
              value={values.patientsGender}
              onChange={handleChange}
              error={touched.patientsGender && Boolean(errors.patientsGender)}
              inputProps={{ readOnly: role === RoleName.Referrer || role === RoleName.Patient }}
              options={[
                { label: 'Sin especificar', value: 'O' },
                { label: 'Femenino', value: 'F' },
                { label: 'Masculino', value: 'M' },
              ]}
              variant="outlined"
            />
            <ViewerTextField
              className="viewer-textfield"
              label="Email"
              name="patientsEmail"
              value={values.patientsEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.patientsEmail && Boolean(errors.patientsEmail)}
              helperText={touched.patientsEmail && errors.patientsEmail}
              readOnly={role === RoleName.Referrer || role === RoleName.Patient}
            />
            <If
              condition={
                role === RoleName.Radiologist ||
                role === RoleName.Technician ||
                role === RoleName.System
              }
            >
              <Actions
                onAccept={handleSubmit}
                onCancel={() => handleCancel(resetForm)}
                isLoading={isUpdating || isSharing || isLoading}
                disabled={existingPatient !== null}
              />
            </If>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default PatientInfo;
