import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import getPatient from '../api/getPatient';

export const GET_PATIENT_KEY = 'GetPatient';

const useGetPatient = () => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const getPatientAsync = async (patientId: string) => {
    try {
      setIsLoading(true);
      const data = await queryClient.fetchQuery([GET_PATIENT_KEY], () => getPatient(patientId), {});
      return data;
    } catch (error: unknown) {
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getPatientAsync,
    isLoading,
  };
};

export default useGetPatient;
