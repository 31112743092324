import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    zIndex: 10000,
    '& .MuiPaper-root': {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#777',
        height:'0px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#555',
        borderRadius: '20px',
      },
    },
  },
});

export default useSx;
