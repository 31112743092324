import { post } from '@core/api/baseApi';
import { ResultResponse } from '@core/types';
import { Patient } from '@core/types/Patient';

const overridePatient = async (patient: {
  patientId: string;
  oldPatientId?: string;
  patientsBirthDate?: string;
  patientsSex?: string;
  patientsNameFamily?: string;
  patientsNameGiven?: string;
  patientsAge?: string;
  patientsEmail?: string;
  patientsPhoneNumber?: string;
}): Promise<ResultResponse<Patient>> => await post(`/UserPatient/override`, patient);

export default overridePatient;
