import { patch } from '@core/api/baseApi';

export async function updatePendingReportById(
  reportId: string,
  report: any,
  reportStatus?: number,
): Promise<{ success: boolean }> {
  try {
    const res = (await patch(`/studies/reports/${reportId}/updateById`, {
      ...report,
      reportStatus,
    })) as {
      message?: string;
      statusCode?: number;
    };

    return { success: !res.message && res.statusCode !== 404 };
  } catch (err) {
    return { success: false };
  }
}
