import { put } from '@core/api/baseApi';

export const updateReport = async (
  report: any,
  studyInstanceUid: string,
  sopInstanceUid: string,
  seriesInstanceUid: string,
): Promise<{ success: boolean }> => {
  try {
    const response = (await put(
      `/studies/${studyInstanceUid}/reports/${sopInstanceUid}/${seriesInstanceUid}`,
      report,
    )) as {
      message?: string;
    };

    return { success: !response.message };
  } catch (err) {
    return { success: false };
  }
};
