import React from 'react';
import { Avatar, AvatarProps, SxProps, Theme } from '@mui/material';
import useSx from './sx';

type Props = {
  modalities: string;
  excludeModalities?: Set<string>;
  splitBy?: string;
} & AvatarProps;

const ModalityAvatar = ({
  modalities,
  sx,
  excludeModalities = new Set(),
  splitBy = ',',
}: Props) => {
  const filteredModalities = modalities
    ?.split(splitBy)
    .map((mod) => mod.trim())
    .filter((mod) => !excludeModalities.has(mod))[0];

  const customSx = useSx(filteredModalities);
  return (
    <Avatar sx={{ ...customSx.root, ...sx } as SxProps<Theme>}>
      {filteredModalities?.toUpperCase() || 'NA'}
    </Avatar>
  );
};

export default ModalityAvatar;
