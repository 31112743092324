import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { OpenInNew } from '@mui/icons-material';
import { Box, TableCell, TableRow, Tooltip } from '@mui/material';
import If from '@components/If';
import ModalityAvatar from '@components/ModalityAvatar';
import TableLayout from '@components/TableLayout';
import { StudyListItemModel } from '@core/types';
import useGetPatientStudies from 'app/features/study/hooks/useGetPatientStudies';

type Props = {
  patientId: string;
  applyFilter?: (study: StudyListItemModel) => boolean;
};

const PatientStudies = ({ patientId, applyFilter }: Props) => {
  const { patientStudies } = useGetPatientStudies({ patientId, enabled: true });

  const filteredStudies = patientStudies?.result.filter((study) => {
    if (!applyFilter) return true;
    return applyFilter(study);
  });

  const tableHeaders = useMemo(
    () => [
      { label: 'MOD', key: 'modality' },
      { label: 'Descripcion', key: 'patientFullName' },
      { label: 'Fecha', key: 'reportDate' },
    ],
    [],
  );

  function handleOpenStudyOnNewWindow(studyInstanceUid: string) {
    // Retrieve the base URL dynamically
    const baseUrl = window.location.origin;

    // Construct the URL using the base URL and study UID
    const url = `${baseUrl}/studies/${studyInstanceUid}`;

    // Open the new URL in a new tab
    window.open(url, '_blank');
  }

  return (
    <TableLayout headers={tableHeaders}>
      <If
        condition={!!filteredStudies && filteredStudies?.length > 0}
        fallback={
          <TableRow>
            <TableCell colSpan={100}>
              Actualmente no hay estudios relacionados con el ID de paciente ingresado
            </TableCell>
          </TableRow>
        }
      >
        {filteredStudies?.map((study) => (
          <Tooltip key={study.studyKey} title="Abrir en nueva ventana">
            <TableRow
              onClick={() => handleOpenStudyOnNewWindow(study.studyInstanceUid)}
              sx={{
                cursor: 'pointer',
              }}
            >
              <TableCell>
                <ModalityAvatar modalities={study.modalities || ''} />
              </TableCell>
              <TableCell sx={{ maxWidth: 160 }}>
                {study.studyDescription || 'Sin descripcion'}
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {dayjs(study.studyDate).format('DD/MM/YYYY')}
                  <OpenInNew color="primary" />
                </Box>
              </TableCell>
            </TableRow>
          </Tooltip>
        ))}
      </If>
    </TableLayout>
  );
};

export default PatientStudies;
