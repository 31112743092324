import { useMutation } from '@tanstack/react-query';
import { rejectReport } from '../api/rejectReport';

const useRejectReport = () => {
  const { isLoading: isRejecting, mutateAsync: rejectPendingReport } = useMutation(
    (reportId: string) => rejectReport(reportId),
  );

  return { isRejecting, rejectPendingReport };
};

export default useRejectReport;
