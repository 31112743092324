/* eslint-disable @typescript-eslint/naming-convention */
import { get } from '@core/api/baseApi';

const getGeneratedReports = (
  studyInstanceUid: string,
): Promise<{
  reportIds: Array<string>;
  hasPendingOrDraft: boolean;
}> => get(`/studies/${studyInstanceUid}/reports/generated`);

export { getGeneratedReports };
