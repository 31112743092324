import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    overflow: 'visible',
    zIndex: 9000,
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    '& .MuiPaper-root': {
      overflow: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#777',
        height:'0px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#555',
        borderRadius: '4px',
      },
    },
    '& .MuiMenu-list': {
      minWidth: '14rem',
      marginBottom: '1px',
    },
    '& .MuiDivider-root': {
      mx: -2,
      my: 1,
    },
  },
});

export default useSx;
