import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },
    '.Mui-disabled': {
      backgroundColor: (theme) => theme.palette.shade?.[300],
    },
    '.input': {
      width: '100%',
    },
  },
});

export default useSx;
