import { get } from '@core/api/baseApi';

interface GetEditableReportResponse {
  encodedDocument: string;
  title: string;
  reportStatusId: number;
  createdBy: {
    userId: string;
    signature: string;
    fullName: string;
    information: string;
  };
}

const getEditableReport = async (
  studyInstanceUid: string,
  sopInstanceUid: string,
  isDraft?: boolean,
): Promise<GetEditableReportResponse> =>
  await get<GetEditableReportResponse>(
    `/studies/${studyInstanceUid}/reports/${sopInstanceUid}/editable`,
    {
      params: {
        isDraft,
      },
    },
  );

export default getEditableReport;
