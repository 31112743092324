import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import If from '@components/If';
import getMenuItems from '@components/Layout/MainLayout/api/getMenuItems';
import useProfile from '@core/hooks/useProfile';
import { RoleName } from '@core/types';
import MenuItemIcon from '../MenuItemIcon';
import ProfileMenuInfoCard from '../ProfileMenuCard';
import ProfileMenuProps from '../ProfileMenuProps';
import OrganizationsMenu from './OrganizationsMenu';
import useSx from './sx';

const LargeViewMenu = ({ anchorEl, onMenuItemClick, onClose }: ProfileMenuProps) => {
  const { hasMoreOrganizations, role, hasAdminPermissions, organizations } = useProfile();
  const sx = useSx();
  const handleItemClick = (item: string) => onMenuItemClick?.(item);
  const menuItems = getMenuItems();

  const [organizationsMenuElement, setOrganizationsMenuElement] = useState<
    Element | null | undefined
  >(null);

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => {
        onClose?.();
        setOrganizationsMenuElement(null);
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      sx={sx.root}
    >
      <ProfileMenuInfoCard />
      <Divider />
      {/* this div prevents a React error about MenuItems not accepting a Fragment */}
      <div>
        {menuItems
          .filter((m) => m.enabled && (m.roles[role || RoleName.Anonymous] || hasAdminPermissions))
          .filter((m) => m.key !== 'switch' || (hasMoreOrganizations && m.key === 'switch'))
          .map((m) => (
            <React.Fragment key={m.key}>
              {m.divider && <Divider />}
              <MenuItem onClick={() => handleItemClick(m.key)}>
                {m.icon && <MenuItemIcon>{m.icon}</MenuItemIcon>}
                <ListItemText>{m.label}</ListItemText>
              </MenuItem>
            </React.Fragment>
          ))}
      </div>
      <If condition={(organizations || []).length > 1}>
        <Divider />
        <MenuItem
          onClick={(e) => {
            setOrganizationsMenuElement(e.currentTarget);
          }}
        >
          <MenuItemIcon>autorenew</MenuItemIcon>
          <ListItemText>Cambiar de organización</ListItemText>
          <MenuItemIcon sx={{ justifyContent: 'flex-end' }}>keyboard_arrow_right</MenuItemIcon>
        </MenuItem>
      </If>

      <Divider />
      <MenuItem onClick={() => handleItemClick('exit')}>
        <MenuItemIcon>power_settings_new</MenuItemIcon>
        <ListItemText
          primaryTypographyProps={{
            style: {
              fontWeight: 'bold',
            },
          }}
        >
          Salir
        </ListItemText>
      </MenuItem>
      <OrganizationsMenu
        anchorElement={organizationsMenuElement}
        setAnchorElement={setOrganizationsMenuElement}
        onClose={onClose}
      />
    </Menu>
  );
};

export default LargeViewMenu;
