import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import If from '@components/If';
import PaginationRow from '@components/PaginationRow';

type Headers = {
  label: string;
  key: string;
  onClick?: () => void;
};

type Props = {
  headers: Headers[];
  withPagination?: boolean;
  pageSize?: number;
  onChangePageSize?: (number: number) => void;
  pageSizeRange?: number[];
  pageCount?: number;
  onPageChange?: (number: number) => void;
  currentPage?: number;
  totalCount?: number;
  children: React.ReactNode;
};

const noop = () => {};

const TableLayout = ({ children, withPagination, headers, ...paginationProps }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell
                sx={{ cursor: !!header.onClick ? 'pointer' : 'auto' }}
                key={header.key}
                onClick={header.onClick}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
          <If condition={withPagination}>
            <PaginationRow
              pageSize={paginationProps.pageSize || 5}
              onChangePageSize={paginationProps.onChangePageSize || noop}
              pageSizeRange={paginationProps.pageSizeRange || [5, 10, 15]}
              pageCount={paginationProps.pageCount || 3}
              onPageChange={paginationProps.onPageChange || noop}
              currentPage={paginationProps.currentPage || 1}
              totalCount={paginationProps.totalCount || 0}
            />
          </If>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableLayout;
