import SxDef from '@core/types/SxDef';

const useSx = (modality?: string): SxDef => {
  const hasModality = modality && modality.length > 0;

  return {
    root: {
      bgcolor: hasModality ? 'primary.dark' : 'text.disabled',
      color: 'primary.contrastText',
      fontWeight: 'bold',
    },
  };
};

export default useSx;
