import React from 'react';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Input, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import CountryCodeDropdown from '@components/CountryCodeDropdown';
import DatePickerWithMobileOverride from '@components/DatePickerWithLabel/DatePickerWithMobileOverride';
import Dropdown from '@components/TypeaheadInput/Dropdown';
import ViewerDialog from '@components/ViewerDialog';
import RegexPatterns from '@core/constants/RegexPatterns';
import { Patient } from '@core/types/Patient';
import useSx from './sx';

const validationSchema = Yup.object().shape({
  patientsAge: Yup.string(),
  patientsGivenName: Yup.string(),
  patientsSurname: Yup.string(),
  patientsGender: Yup.string().oneOf(['M', 'F', 'O'], 'El género debe ser M, F u O').max(1),
  patientsEmail: Yup.string(),
  patientsPhoneNumber: Yup.string(),
  studyDescription: Yup.string(),
  referringPhysiciansName: Yup.string(),
});

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  existingPatient: Patient;
  newPatient: Patient;
  isLoading?: boolean;
  onSubmit: (payload: {
    patientId: string;
    oldPatientId: string;
    patientsNameFamily: string;
    patientsNameGiven: string;
    patientsAge: string;
    patientsEmail: string;
    patientsPhoneNumber: string;
    patientsSex: string;
  }) => void;
};

const OverridePatientFormDialog = ({
  isOpen,
  onClose,
  existingPatient,
  newPatient,
  onSubmit,
  isLoading = false,
}: Props) => {
  const sx = useSx();

  const initialValuesFormatted = {
    ...newPatient,
    patientsPhoneNumber:
      newPatient?.patientsPhoneNumber?.match(RegexPatterns.PHONE_NUMBER)?.[2] || '',
    patientsCountryCodePhoneNumber:
      newPatient?.patientsPhoneNumber?.match(RegexPatterns.PHONE_NUMBER)?.[1] || '',
  };

  const handleSubmitMediator = (values: typeof initialValuesFormatted) => {
    const phoneNumber =
      values.patientsCountryCodePhoneNumber && values.patientsPhoneNumber
        ? `(${values.patientsCountryCodePhoneNumber})${values.patientsPhoneNumber}`
        : '';

    const payload = {
      patientId: existingPatient?.patientId ?? '',
      oldPatientId: newPatient?.patientId ?? '',
      patientsNameFamily: values.patientsNameFamily,
      patientsNameGiven: values.patientsNameGiven,
      patientsAge: values.patientsAge,
      patientsEmail: values.patientsEmail,
      patientsPhoneNumber: phoneNumber,
      patientsSex: values.patientsSex,
    };

    onSubmit(payload);
    onClose();
  };

  function getDisplayGender(str?: string) {
    switch (str) {
      case 'F':
        return 'Femenino';
      case 'M':
        return 'Masculino';
      default:
        return 'Sin Especificar';
    }
  }

  return (
    <ViewerDialog open={isOpen} onClose={onClose} title="Datos del paciente con ID coincidente">
      <Formik
        initialValues={initialValuesFormatted}
        onSubmit={handleSubmitMediator}
        validationSchema={validationSchema}
        validateOnChange
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue, isValid }) => (
          <Box sx={sx.root} component="form" onSubmit={handleSubmit}>
            <Typography fontSize="0.875rem">
              El ID pertenece a este paciente, para vincularlo modifica los datos deseados y acepta
              los cambios. <br /> Si no deseas vincular el estudio, cierra la ventana y asigna otro
              ID.
            </Typography>
            <Box display="flex" gap={(theme) => theme.spacing(4)} mt={2}>
              <Box display="flex" flexDirection="column" flex={1} gap={(theme) => theme.spacing(4)}>
                <Typography>Información del paciente con ID coincidente</Typography>
                <Box width="100%" className="input-container">
                  <InputLabel>Nombre</InputLabel>
                  <Input className="input" value={existingPatient?.patientsNameGiven} disabled />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Apellido</InputLabel>
                  <Input className="input" value={existingPatient?.patientsNameFamily} disabled />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Fecha de nacimiento</InputLabel>
                  <Input className="input" value={existingPatient?.patientsBirthDate} disabled />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Género</InputLabel>
                  <Input
                    className="input"
                    value={getDisplayGender(existingPatient?.patientsSex)}
                    disabled
                  />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Edad</InputLabel>
                  <Input className="input" value={existingPatient?.patientsAge} disabled />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Email</InputLabel>
                  <Input className="input" value={existingPatient?.patientsEmail} disabled />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Número de teléfono</InputLabel>
                  <Input className="input" value={existingPatient?.patientsPhoneNumber} disabled />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" flex={1} gap={(theme) => theme.spacing(4)}>
                <Typography>Nuevo perfil (actualización de datos)</Typography>
                <Box width="100%" className="input-container">
                  <InputLabel>Nombre</InputLabel>
                  <TextField
                    className="input"
                    value={values.patientsNameGiven}
                    name="patientsNameGiven"
                    error={!!errors.patientsNameGiven}
                    helperText={!!errors.patientsNameGiven}
                    onChange={handleChange}
                  />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Apellido</InputLabel>
                  <TextField
                    className="input"
                    value={values.patientsNameFamily}
                    name="patientsNameFamily"
                    error={!!errors.patientsNameFamily}
                    helperText={!!errors.patientsNameFamily}
                    onChange={handleChange}
                  />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Fecha de nacimiento</InputLabel>
                  <DatePickerWithMobileOverride
                    label=""
                    value={dayjs(values.patientsBirthDate)}
                    onChange={(value) => {
                      setFieldValue('patientsBirthDate', value);
                    }}
                  />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Género</InputLabel>
                  <Select className="input" defaultValue={'O'}>
                    <MenuItem value={'O'}>Sin especificar</MenuItem>
                    <MenuItem value={'F'}>Femenino</MenuItem>
                    <MenuItem value={'M'}>Masculino</MenuItem>
                  </Select>
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Edad</InputLabel>
                  <TextField
                    className="input"
                    value={values.patientsAge}
                    name="patientsAge"
                    error={!!errors.patientsAge}
                    helperText={!!errors.patientsAge}
                    onChange={handleChange}
                  />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Email</InputLabel>
                  <TextField
                    className="input"
                    value={values.patientsEmail}
                    name="patientsEmail"
                    error={!!errors.patientsEmail}
                    helperText={!!errors.patientsEmail}
                    onChange={handleChange}
                  />
                </Box>
                <Box width="100%" className="input-container">
                  <InputLabel>Número de teléfono</InputLabel>
                  <Box display="flex" gap={(theme) => theme.spacing(1)}>
                    <CountryCodeDropdown
                      name="patientsCountryCodePhoneNumber"
                      onChange={handleChange}
                      sx={{ width: '30%' }}
                      label="Código"
                      value={values.patientsCountryCodePhoneNumber}
                    />
                    <Input
                      name="patientsPhoneNumber"
                      placeholder="Número"
                      value={values.patientsPhoneNumber}
                      onChange={handleChange}
                      sx={{ flex: 1 }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              gap={(theme) => theme.spacing(4)}
              mt={(theme) => theme.spacing(4)}
            >
              <ActionButton
                onClick={onClose}
                variant="contained"
                color="secondary"
                text="Cancelar"
                isLoading={isLoading}
                disabled={isLoading || !isValid}
              />
              <ActionButton type="submit" variant="contained" color="primary" text="Aceptar" />
            </Box>
          </Box>
        )}
      </Formik>
    </ViewerDialog>
  );
};

export default OverridePatientFormDialog;
