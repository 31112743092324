import { get } from '@core/api/baseApi';

interface Response {
  pendingReports: {
    firstName: string;
    lastName: string;
    modifiedDate: string;
    reportId: number;
    status: 4 | 2;
  }[];
}

export const getPendingReports = async (studyInstanceUid?: string): Promise<Response> => {
  return await get(`/studies/${studyInstanceUid}/reports/pending`);
};
