import { useQuery } from '@tanstack/react-query';
import { getPendingReports } from '../api/getPendingReports';

export const GET_PEDING_REPORTS_QUERY_KEY = 'GetPendingReports';

const useGetPendingReports = (studyInstanceUid?: string) => {
  return useQuery({
    queryKey: [GET_PEDING_REPORTS_QUERY_KEY],
    queryFn: () => getPendingReports(studyInstanceUid),
    enabled: !!studyInstanceUid,
  });
};

export default useGetPendingReports;
