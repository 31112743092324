import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import If from '@components/If';
import AssignedRadiologistsProps from './AssignedRadiologistsProps';
import useSx from './sx';

const AssignedRadiologists = ({
  assignedRadiologist = [],
  onRadiologistClick,
  isActive,
}: AssignedRadiologistsProps) => {
  const sx = useSx();
  return (
    <Box display="flex" alignItems="center" gap="5px" flexWrap="wrap">
      <If condition={assignedRadiologist!.length > 0}>
        {assignedRadiologist.map((radiologist) => (
          <Chip
            onClick={onRadiologistClick.bind(null, radiologist)}
            key={radiologist.userUid}
            label={radiologist.givenName + ' ' + radiologist.surname}
            sx={isActive(radiologist) ? sx.activeChip : sx.chip}
          />
        ))}
      </If>
    </Box>
  );
};

export default AssignedRadiologists;
