import React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PageSize from './PageSize';
import Pagination from './Pagination';
import useSx from './sx';

const PaginationRow = ({
  onChangePageSize,
  pageSizeRange,
  pageCount,
  onPageChange,
  currentPage,
  totalCount,
  pageSize,
}: {
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  pageSizeRange: number[];
  pageCount: number;
  onPageChange: (page: number) => void;
  currentPage: number;
  totalCount: number;
}) => {
  const sx = useSx();

  return (
    <TableRow sx={sx.tableFooterRow}>
      <TableCell colSpan={100}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <PageSize
            pageSize={pageSize}
            pageSizeRange={pageSizeRange}
            onChangePageSize={onChangePageSize}
          />
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageCount={pageCount}
            pageSize={pageSize}
            onPageChange={onPageChange}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default PaginationRow;
