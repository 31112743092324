import React from 'react';
import Box from '@mui/material/Box';
import ActionButton from '@components/Button/ActionButton';
import useSx from './sx';

export interface ActionsProps {
  onCancel?: VoidFunction;
  onAccept?: VoidFunction;
  isLoading?: boolean;
  disabled?: boolean;
}

const Actions = ({ onAccept, onCancel, isLoading, disabled = false }: ActionsProps) => {
  const sx = useSx();

  return (
    <Box sx={sx.root} className="ReportManagerActions-root">
      <ActionButton
        text="Reiniciar"
        color="secondary"
        variant="outlined"
        onClick={onCancel}
        sx={sx.button}
      />
      <ActionButton
        text="Guardar"
        color="primary"
        variant="contained"
        onClick={onAccept}
        isLoading={isLoading}
        sx={sx.button}
        disabled={disabled}
      />
    </Box>
  );
};

export default Actions;
