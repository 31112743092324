import {
  draftDoctorProfessionalInfoPlaceholder,
  draftDoctorSignaturePlaceholder,
  professionalInfoPlaceholder,
  qrPlaceholder,
  signaturePlaceholder,
} from 'app/features/templates/add/components/FormDialog/imagePlaceholders';

function insertVariable(variableName: string) {
  return `<span class="${variableName}" style="background-color: var(--darkColor_lightgray, lightgray)">{{${variableName}}}</span>`;
}

export const initialTemplate = `
<p style="margin: 0; line-height: 1.3;"><br></p>
<p style="margin: 0; line-height: 1.3;"><br></p>
<p style="margin: 0; line-height: 1.3;"><br></p>
<p style="margin: 0; line-height: 1.3;"><br></p>
<p style="margin: 0; line-height: 1.3;"><br></p>
<p style="margin: 0; line-height: 1.3;"><br></p>
<p style="margin: 0; line-height: 1.3;">
    <b>NOMBRE:</b> ${insertVariable('Paciente_Nombre')} ${insertVariable(
  'Paciente_Apellido',
)}, ${insertVariable('Paciente_Edad')}
</p>
<p style="margin: 0; line-height: 1.3;">
    <b>ESTUDIO:</b> ${insertVariable(
      'Estudio_Nombre',
    )}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>FECHA:</b> ${insertVariable(
  'Estudio_Fecha',
)}
</p>
<p style="margin: 0; line-height: 1.3;">
    DR. ${insertVariable('Doctor')}
</p>
<p style="margin: 0; line-height: 1.3;">&nbsp;</p>
`;

export const initialSignature = `
<p style="margin: 0; line-height: 1.3"><br /></p>
<p style="margin: 0; line-height: 1.3"><br /></p>
<p style="margin: 0; line-height: 1.3"><br /></p>
<div id="footer-editor" style="line-height: 1.2em">
  <div style="position:relative;display:flex;justify-content:flex-end;align-items:center;">
    <div style="position:absolute;left:50%;transform:translateX(-50%)">  
      ${signaturePlaceholder().innerHTML}
    </div>
    <div style="margin-left:auto">
      ${qrPlaceholder().innerHTML}
    </div>
  </div>
  <div style="text-align: center">
    ${professionalInfoPlaceholder().innerHTML}
  </div>
</div>`;

export const initialDoubleSignature = `
<p style="margin: 0; line-height: 1.3"><br /></p>
<p style="margin: 0; line-height: 1.3"><br /></p>
<p style="margin: 0; line-height: 1.3"><br /></p>
<div id="footer-editor" style="line-height: 1.2em">
  <div style="position:relative;display:flex;justify-content:space-between;align-items:center;">
    <div>  
      ${draftDoctorSignaturePlaceholder().innerHTML}
    </div>
    <div style="position:absolute;left:50%;transform:translateX(-50%)">
      ${qrPlaceholder().innerHTML}
    </div>
    <div>  
      ${signaturePlaceholder().innerHTML}
    </div>
  </div>
  <div style="display:flex;justify-content:space-between;align-items:center;">
    <div>
      ${draftDoctorProfessionalInfoPlaceholder().innerHTML}
    </div>
    <div>  
      ${professionalInfoPlaceholder().innerHTML}
    </div>
  </div>
</div>`;
