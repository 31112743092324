import { useMutation } from '@tanstack/react-query';
import { updateReport } from '../api/updateReport';

export default function useUpdateReport(studyInstanceUid: string) {
  const { isLoading: isUpdatingReport, mutateAsync: reportUpdate } = useMutation(
    (data: { report: any; sopInstanceUid: string; seriesInstanceUid: string }) =>
      updateReport(data.report, studyInstanceUid, data.sopInstanceUid, data.seriesInstanceUid),
  );

  return { isUpdatingReport, reportUpdate };
}
