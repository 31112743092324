import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { STUDY_QUERY_KEY } from '@core/hooks/useStudyInfo';
import overridePatient from '../api/overridePatient';
import updateStudy from '../api/updateStudy';

export const GET_PATIENT_KEY = 'GetPatient';

const useOverridePatient = (studyInstanceUid: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync: override, ...rest } = useMutation({
    mutationFn: (patient: {
      patientId: string;
      oldPatientId?: string;
      patientsBirthDate?: string;
      patientsSex?: string;
      patientsNameFamily?: string;
      patientsNameGiven?: string;
      patientsAge?: string;
      patientsEmail?: string;
      patientsPhoneNumber?: string;
    }) => overridePatient(patient),
    onSuccess: async (data, payload) => {
      await updateStudy(studyInstanceUid, {
        patientsGivenName: payload.patientsNameGiven,
        patientsSurname: payload.patientsNameFamily,
        patientsBirthDate: dayjs(payload.patientsBirthDate),
        patientsAge: payload.patientsAge,
      });
      queryClient.invalidateQueries([STUDY_QUERY_KEY]);
    },
  });

  return { override, ...rest };
};

export default useOverridePatient;
