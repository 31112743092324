import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '16px',
    padding: '1rem',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fff1',
    },
  },
  subtitle: {
    fontSize: '12px',
  },
  nameAndIcon: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridAutoFlow: 'column',
    gap: '0 1.5rem',
    alignItems: 'center',
  },
  icon: {
    gridRow: 'span 2',
    width: '24px',
    height: '24px',
  },
  draft: {
    gridRow: 'span 2',
  },
});

export default useSx;
