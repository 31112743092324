import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  tableFooterRow: {
    borderBottom: 'none',
    '.page-size-title': {
      display: { xs: 'none', md: 'inline-block' },
      textTransform: 'none',
    },
  },
});

export default useSx;
