import { RoleName } from '@core/types';
import viewerConfig from '../../config/viewer-config';

export interface DeleteSeriesHandler {
  (
    displaySet: {
      StudyInstanceUID: string;
      SeriesInstanceUID: string;
      Modality: string;
      SOPInstanceUID: string;
    },
    existingSeriesCount: number,
  ): Promise<boolean>;
}

export interface ConfigOptions {
  studyInstanceUid: string;
  isAnon: boolean;
  isAdministrator: boolean;
  role: RoleName | undefined;
  deleteSeriesHandler: DeleteSeriesHandler;
  routerBasename: string;
  reportsCreatedByUser: Array<string>;
  generatedReportIds: Array<string>;
  hasPendingOrDraftReport?: boolean;
}

const config = ({
  studyInstanceUid,
  isAnon,
  isAdministrator,
  deleteSeriesHandler,
  role,
  routerBasename,
  generatedReportIds,
  reportsCreatedByUser,
  hasPendingOrDraftReport = false,
}: ConfigOptions) => {
  const preConfiguration = viewerConfig(
    generatedReportIds,
    reportsCreatedByUser,
    hasPendingOrDraftReport,
  );
  return {
    ...preConfiguration,
    evodicomConfig: {
      ...preConfiguration.evodicomConfig,
      isAnon,
      isAdministrator,
      role,
      customActions: {
        deleteSeries: {
          callback: deleteSeriesHandler,
        },
      },
      studyInstanceUIDs: studyInstanceUid,
      reportsCreatedByUser,
      toolbar: {
        enableMobileToolbar: window.screen.width < 900,
        mobile: {
          // Add the button id to the corresponding array to use this function
          renderOutsideFoldable: ['StackScroll', 'Wwwc', 'Data', 'review'],
          hide: ['printPhoto'],
        },
      },
    },
    routerBasename,
    sortSeriesBy3DPosition: false,
  };
};

export default config;
