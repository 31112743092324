import React, { useMemo, useState } from 'react';
import { Input, ListItemButton, ListItemText, PopoverOrigin } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Menu from '@mui/material/Menu';
import SearchIcon from '@components/SearchInput/SearchIcon';
import useChangeOrganizations from '@core/hooks/useChangeOrganizations';
import useProfile from '@core/hooks/useProfile';
import { UserOrganizationModel } from '@core/types';
import OrganizationsMenuProps from './OrganizationsMenuProps';
import useSx from './sx';

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'center',
  horizontal: 'right',
};

const OrganizationsMenu = ({
  anchorElement,
  setAnchorElement,
  onClose,
}: OrganizationsMenuProps) => {
  const { organizations, userUid } = useProfile();
  const [searchTerm, setSearchTerm] = useState('');
  const changeOrganization = useChangeOrganizations();
  const sx = useSx();

  const filteredOrganizations = useMemo(() => {
    return organizations?.filter((org) =>
      org.organizationName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [organizations, searchTerm]);

  const onOrganizationChange = (organizationItem: UserOrganizationModel) => {
    changeOrganization({
      userUid,
      organizationProductId: organizationItem.organizationProductId,
      organizations,
    });
    localStorage.removeItem('savedSelectedFilters');
    setAnchorElement(undefined);
    onClose?.();
  };

  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={(e) => {
        setAnchorElement(undefined);
      }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={sx.root}
    >
      <Autocomplete
        options={organizations || []}
        getOptionLabel={(option) => option.organizationName}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
        }}
        onKeyDown={(e) => e.stopPropagation()}
        renderInput={(params) => (
          <Input
            {...params}
            inputProps={params.inputProps}
            placeholder="Buscar organización"
            fullWidth
            startAdornment={<SearchIcon />}
          />
        )}
      />
      {filteredOrganizations?.map((organizationItem) => (
        <ListItemButton
          onClick={() => {
            onOrganizationChange(organizationItem);
          }}
          key={organizationItem.organizationProductId}
          sx={{ pl: 8 }}
        >
          <ListItemText primary={organizationItem.organizationName} />
        </ListItemButton>
      ))}
    </Menu>
  );
};

export default OrganizationsMenu;
