import React, { useContext } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, Divider } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import RestrictToRole from '@components/RestrictToRole';
import SectionHeader from '@components/SectionHeader';
import useProfile from '@core/hooks/useProfile';
import useSetUserSettings from '@core/hooks/useSetUserSettings';
import useSnackbar from '@core/hooks/useSnackbar';
import { ThemeContext } from '@core/providers/ThemeProvider';
import { RoleName } from '@core/types';

const Notifications = () => {
  const { storedSettings } = useContext(ThemeContext);
  const user = useProfile();
  const userHasPhoneNumber = Number(user.userProfileInfo?.phoneNumber);
  const showSnackbar = useSnackbar();

  const userNotificationsEmailPreferences = storedSettings?.notifications;
  const userNotificationsWhatsappPreferes = storedSettings?.notificationsByWhatsapp;

  //If there are notifications preferences, it should be shown as active by default
  const userPrefersNotificationsByEmail =
    userNotificationsEmailPreferences?.studies === undefined
      ? true
      : Boolean(userNotificationsEmailPreferences?.studies);

  const userPrefersPriorityNotificationsByEmail =
    userNotificationsEmailPreferences?.priorityStudies === undefined
      ? true
      : Boolean(userNotificationsEmailPreferences?.priorityStudies);

  const userPrefersNotificationsByWhatsapp = Boolean(userNotificationsWhatsappPreferes?.studies);

  const userPrefersPriorityNotificationsByWhatsapp = Boolean(
    userNotificationsWhatsappPreferes?.priorityStudies,
  );

  const { setUserSettings } = useSetUserSettings();
  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    type: 'notifications' | 'notificationsByWhatsapp',
  ) => {
    if (type === 'notificationsByWhatsapp' && !userHasPhoneNumber) {
      showSnackbar({
        type: 'error',
        message: 'Para recibir notificaciones por WhatsApp, debes tener un número de teléfono.',
        title: 'Error',
      });
    }

    const temporaryStoredSettings = { ...storedSettings };
    temporaryStoredSettings[type] = temporaryStoredSettings[type]
      ? temporaryStoredSettings[type]
      : {};
    temporaryStoredSettings[type][e.target.name] = checked;

    await setUserSettings(JSON.stringify(temporaryStoredSettings));
  };

  const isWhatsappStudiesEnabled =
    Boolean(userHasPhoneNumber) && userPrefersNotificationsByWhatsapp;
  const isWhatsappPriorityStudiesEnabled =
    Boolean(userHasPhoneNumber) && userPrefersPriorityNotificationsByWhatsapp;

  return (
    <RestrictToRole allowedRoles={[RoleName.Radiologist, RoleName.System]}>
      <SectionHeader title="Notificaciones" IconComponent={NotificationsIcon} />
      <Divider />
      <Box marginTop="2rem" display="flex" flexDirection="column">
        <FormControlLabel
          control={
            <Switch
              onChange={(e, checked) => handleChange(e, checked, 'notifications')}
              checked={userPrefersNotificationsByEmail}
              inputProps={{ name: 'studies' }}
            />
          }
          label="Recibir notificaciones por correo electrónico cuando se me asigna un estudio."
        />
        <FormControlLabel
          control={
            <Switch
              onChange={(e, checked) => handleChange(e, checked, 'notifications')}
              checked={userPrefersPriorityNotificationsByEmail}
              inputProps={{ name: 'priorityStudies' }}
            />
          }
          label="Recibir notificaciones por correo electrónico cuando se me asigna un estudio con prioridad alta."
        />
        <FormControlLabel
          control={
            <Switch
              onChange={(e, checked) => handleChange(e, checked, 'notificationsByWhatsapp')}
              checked={isWhatsappStudiesEnabled}
              inputProps={{ name: 'studies' }}
            />
          }
          label="Recibir notificaciones por WhatsApp cuando se me asigna un estudio."
        />
        <FormControlLabel
          control={
            <Switch
              onChange={(e, checked) => handleChange(e, checked, 'notificationsByWhatsapp')}
              checked={isWhatsappPriorityStudiesEnabled}
              inputProps={{ name: 'priorityStudies' }}
            />
          }
          label="Recibir notificaciones por WhatsApp cuando se me asigna un estudio con prioridad alta."
        />
      </Box>
    </RestrictToRole>
  );
};

export default Notifications;
