import { useMutation, useQueryClient } from '@tanstack/react-query';
import { STUDY_QUERY_KEY } from '@core/hooks/useStudyInfo';
import { GET_PATIENT_STUDIES_KEY } from 'app/features/study/hooks/useGetPatientStudies';
import updateStudyInformation, { Payload } from '../api/updateStudyInformation';
import { QUERY_KEY as STUDIES_QUERY_KEY } from './useStudies';

const useUpdateStudyInformation = () => {
  const queryClient = useQueryClient();

  const { isLoading: isUploading, mutateAsync: updateInformationAsync } = useMutation({
    mutationFn: (payload: Payload) => updateStudyInformation(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([STUDIES_QUERY_KEY, STUDY_QUERY_KEY]);
      queryClient.invalidateQueries([GET_PATIENT_STUDIES_KEY]);
    },
  });

  return { isUploading, updateInformationAsync };
};

export default useUpdateStudyInformation;
