import { useQuery } from '@tanstack/react-query';
import getPatientStudies from '../api/getPatientStudies';

export const GET_PATIENT_STUDIES_KEY = 'GetPatientStudies';

const useGetPatientStudies = ({ patientId, enabled }: { patientId: string; enabled: boolean }) => {
  const { data: patientStudies, ...rest } = useQuery({
    queryKey: [GET_PATIENT_STUDIES_KEY],
    queryFn: () => getPatientStudies(patientId),
    enabled,
  });

  return {
    patientStudies,
    ...rest,
  };
};

export default useGetPatientStudies;
