import React from 'react';
import { useParams } from 'react-router-dom';
import { DescriptionOutlined, Visibility } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import If from '@components/If';
import formatToSpanishDate from '@core/utils/formatDateToSpanishDate';
import useGetPendingReports from 'app/features/study/hooks/useGetPendingReports';
import useSx from './sx';

const clearReportsAssociatedQueries = () => {
  const event = new CustomEvent('evodicom.report.added');
  document.dispatchEvent(event);
};

export default function ReviewDisplay() {
  const { studyInstanceUid } = useParams();
  const { data, isLoading } = useGetPendingReports(studyInstanceUid);

  const sx = useSx();

  function openReportOnNewTab(id: number) {
    // Retrieve the base URL dynamically
    const baseUrl = window.location.origin;

    // Split the current URL path and extract the studyUid
    const pathSegments = window.location.pathname.split('/');
    const studyIndex = pathSegments.findIndex((segment) => segment === 'studies');
    const studyUid = pathSegments[studyIndex + 1];

    // Check if studyUid is available
    if (!studyUid) {
      console.error('No studyUid found in URL path');
      return;
    }

    // Construct the URL using the base URL and study UID
    const url = `${baseUrl}/reports?studyUid=${studyUid}&SOPInstanceUID=${id}&borrador=true`;

    // Open the new URL in a new tab
    const reportWindow = window.open(url, '_blank');
    // @ts-expect-error its defined on root
    window.onSuccessResponse = () => {
      reportWindow?.close();
      clearReportsAssociatedQueries();
      window.location.reload();
    };
    // @ts-expect-error its defined on root
    window.onCancelReport = () => {
      reportWindow?.close();
    };
  }

  return (
    <If condition={!isLoading}>
      <Box sx={sx.root}>
        {data?.pendingReports.map((report) => (
          <Box
            key={report.modifiedDate}
            sx={sx.row}
            onClick={() => openReportOnNewTab(report.reportId)}
          >
            <Box sx={sx.nameAndIcon}>
              <DescriptionOutlined sx={sx.icon} />
              <Typography sx={sx.subtitle}>Reporte por:</Typography>
              <Typography>{`${report.firstName} ${report.lastName}`}</Typography>
              <If condition={report.status === 4}>
                <Typography sx={sx.draft}>Borrador</Typography>
              </If>
            </Box>
            <Box>
              <Typography sx={sx.subtitle}>Fecha de modificación:</Typography>
              <Typography>{formatToSpanishDate(report.modifiedDate, { full: true })}</Typography>
            </Box>
            <Visibility sx={sx.icon} />
          </Box>
        ))}
      </Box>
    </If>
  );
}
