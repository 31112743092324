import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Typography } from '@mui/material';
import If from '@components/If';

type Props = {
  onClick: VoidFunction;
  title: string;
  collapseStatus: boolean;
};

const AccordionHeader = ({ onClick, title, collapseStatus }: Props) => {
  return (
    <Box
      onClick={onClick}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        background: (theme) => theme.palette.shade?.[500],
        padding: '.5rem',
        borderRadius: '15px',
      }}
    >
      <Typography variant="h5" className="title">
        {title}
      </Typography>
      <If condition={collapseStatus} fallback={<KeyboardArrowDownIcon color="primary" />}>
        <KeyboardArrowUpIcon color={'primary'} />
      </If>
    </Box>
  );
};

export default AccordionHeader;
