import { Gender } from '@core/types/Gender';
import replaceProfessionalInfo from './replaceProfessionalInfo';
import replaceQrCode from './replaceQrCode';
import replaceQrLink from './replaceQrLink';
import replaceSignature from './replaceSignature';

interface ProfessionalData {
  shareStudyQrBase64?: string;
  shareStudyUrl?: string;
  createdBy?: {
    fullName?: string;
    information?: string;
    signature?: string;
    gender?: Gender;
  };
  approvedBy?: {
    fullName?: string;
    information?: string;
    signature?: string;
    gender?: Gender;
  };
}

export default function replacePlaceholders(page: HTMLDivElement, data: ProfessionalData) {
  replaceSignature(page, data.createdBy?.signature);
  replaceProfessionalInfo(page, data.createdBy);
  replaceQrCode(page, data.shareStudyQrBase64);
  replaceQrLink(page, data.shareStudyUrl);
  replaceSignature(page, data.approvedBy?.signature, '.DraftDoctorSignaturePlaceholder');
  replaceProfessionalInfo(page, data.approvedBy, '.DraftDoctorProfessionalInfoPlaceholder');
}
