import React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

const PageSize = ({
  pageSize,
  onChangePageSize,
  pageSizeRange,
}: {
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  pageSizeRange: number[];
}) => {
  return (
    <Box display="flex" alignItems="center" gap="10px">
      <Select
        size="small"
        label="Agrandar pagina"
        value={pageSize}
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 360 },
          },
        }}
        onChange={(e) => onChangePageSize(Number(e.target.value))}
      >
        {pageSizeRange.map((range) => (
          <MenuItem key={range.toString()} id={range.toString()} value={range.toString()}>
            {range}
          </MenuItem>
        ))}
      </Select>
      <Typography className="page-size-title">Filas por página</Typography>
    </Box>
  );
};

export default PageSize;
