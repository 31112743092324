import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getGeneratedReports } from '@core/api/getGeneratedReports';

export type UseGeneratedReportIdsResult = Omit<UseQueryResult<any, any>, 'data'> & {
  reportData?: {
    reportIds: Array<string>;
    hasPendingOrDraft: boolean;
  };
};

export const GENERATED_REPORTS_QUERY_KEY = 'GeneratedReportIds';

const useGeneratedReports = (studyId: string): UseGeneratedReportIdsResult => {
  const isEnabled = Boolean(studyId);
  const { data: reportData, ...rest } = useQuery({
    queryFn: () => getGeneratedReports(studyId),
    queryKey: [GENERATED_REPORTS_QUERY_KEY],
    enabled: isEnabled,
  });

  return { reportData, ...rest };
};

export default useGeneratedReports;
