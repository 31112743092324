import { useQuery } from '@tanstack/react-query';
import getEditableReport from '../api/getEditableReport';

export const GET_EDITABLE_REPORT_KEY = 'GetEditableReport';

const useGetEditableReport = ({
  studyInstanceUid,
  sopInstanceUid,
  isDraft,
  enabled,
}: {
  studyInstanceUid: string;
  sopInstanceUid: string;
  isDraft: boolean;
  enabled: boolean;
}) => {
  const { data: editableReport, ...rest } = useQuery({
    queryKey: [GET_EDITABLE_REPORT_KEY],
    queryFn: () => getEditableReport(studyInstanceUid, sopInstanceUid, isDraft),
    enabled,
  });

  return {
    editableReport,
    ...rest,
  };
};

export default useGetEditableReport;
