import { useMutation } from '@tanstack/react-query';
import { updatePendingReportById } from '../api/updatePendingReportById';

const useUpdatePendingReport = () => {
  const { isLoading: isUpdating, mutateAsync: updatePendingReport } = useMutation(
    ({
      reportId,
      reportStatusId,
      report,
    }: {
      reportId: string;
      reportStatusId?: number;
      report: any;
    }) => updatePendingReportById(reportId, report, reportStatusId),
  );

  return { isUpdating, updatePendingReport };
};

export default useUpdatePendingReport;
