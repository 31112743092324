import { Dayjs } from 'dayjs';
import { post } from '@core/api/dicomApi';

interface StudyData {
  patientsGivenName?: string;
  patientsSurname?: string;
  patientsBirthDate?: Dayjs;
  patientsAge?: string;
  patientsGender?: string;
  studyDescription?: string;
  referringPhysiciansName?: string;
}

const updateStudy = async (studyInstanceUid: string, payload: StudyData): Promise<void> => {
  const formatedDate = payload?.patientsBirthDate?.isValid()
    ? payload.patientsBirthDate.format('YYYYMMDD')
    : undefined;

  let formattedDicomData = {};

  if (payload.patientsGivenName || payload.patientsSurname) {
    formattedDicomData = {
      ...formattedDicomData,
      '00100010': {
        vr: 'PN',
        Value: [
          {
            Alphabetic: `${payload.patientsGivenName} ${payload.patientsSurname}`,
          },
        ],
      },
    };
  }

  if (payload.patientsAge) {
    formattedDicomData = {
      ...formattedDicomData,
      '00101010': {
        vr: 'AS',
        Value: [payload.patientsAge],
      },
    };
  }

  if (payload.patientsGender) {
    formattedDicomData = {
      ...formattedDicomData,
      '00100040': {
        vr: 'CS',
        Value: [payload.patientsGender],
      },
    };
  }

  if (payload.studyDescription) {
    formattedDicomData = {
      ...formattedDicomData,
      '00081030': {
        vr: 'LO',
        Value: [payload.studyDescription],
      },
    };
  }

  if (payload.referringPhysiciansName) {
    formattedDicomData = {
      ...formattedDicomData,
      '00080090': {
        vr: 'PN',
        Value: [
          {
            Alphabetic: payload.referringPhysiciansName,
          },
        ],
      },
    };
  }

  if (formatedDate) {
    formattedDicomData = {
      ...formattedDicomData,
      '00100030': {
        vr: 'DA',
        Value: [formatedDate],
      },
    };
  }

  if (!Object.keys(formattedDicomData).length) {
    return;
  }

  await post(`/studies/$bulkUpdate`, {
    studyInstanceUids: [studyInstanceUid],
    changeDataset: formattedDicomData,
  });
};
export default updateStudy;
