export default function replaceSignature(
  page: HTMLDivElement,
  signatureBase64?: string,
  className = '.SignaturePlaceholder',
) {
  const signaturePlaceholders = page.querySelectorAll(className) as NodeListOf<HTMLImageElement>;

  signaturePlaceholders.forEach((elem: HTMLImageElement) => {
    if (signatureBase64) {
      elem.src = `data:image/png;base64,${signatureBase64}`;
    } else {
      if (className === '.SignaturePlaceholder') elem.remove();
    }
  });
}
